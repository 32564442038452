<template>
    <div class="" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
      <button class="btn btn-secondary " type="button" id=""  >
        Dropdown button
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" :class="{ show: showDropdown }">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showDropdown: false,
      }
    },
  }
  </script>
  