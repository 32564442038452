<template>
    <NavView></NavView>
    <SideBar></SideBar>
    
    <div class="container-fluid ">
        <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Dashbord </router-link><span class="pfimg1"></span>   </h6>
    
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  import NavView from '../../components/NavView.vue'
  import SideBar from '../../components/SideBar.vue'
 // import { CChart } from '@coreui/vue-chartjs'
  

  

  
  export default {
   
    components: {
      NavView,SideBar
     },

  data () {
    return {
    
  }
  },
}
  </script>
  <style>
      .manage-boder{
  border-bottom: 2px solid #E9E9E3;
  border-top: 2px solid #E9E9E3;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  
  }

  .font1{
  font-size: 13px !important;

}
.font-bold1{
  font-weight: bold;
}
.decoration{
  text-decoration: none;
  color:#A5A5A3  ;
}
.w-35{
    width: 35%;
}
  </style>
  