<template>
    <div class=" fixed-bottom">
        <!-- <p class="mrg4 fixed-bottom"> Copyright @ 2022 <a href="www.rentalect.com" > www.rentalect.com.</a> All Rights Reserved.</p> -->
        <div class="card-footer text-muted  color3">
    <nav class="row mrg4">
          <div class="col-md-8 mt-2">
           <h6 style="font-size: x-small;">Copyright @ 2023 <a style="color: #337ab7;" href="awww.evenware.com">www.evenware.com</a> All Rights Reserved.</h6> 
          </div>


          <div class="col-md-4" style="text-align: end;">
          <span class="mt-1 me-1" style="font-size: small;">Powered by</span>
          <img src="../assets/evenware-logo.png" width="100px" alt="">
          </div>
  
    </nav>
        </div>


    </div>
    
</template>
<script>
   

</script>
<style scoped>
    .mrg4 {
      padding:0px ;
      }
      
      .link1{
        color: blue;
        cursor: pointer;
      }
     .color3{
      background-color: #fff;;
     }


     @media only screen and (max-width: 768px) {
      
     }
     
      
      
    
</style>