<template>
    <NavView></NavView>
      <SideBar></SideBar>
  <div class="container-fluid mb-5" >
      <!-- <h4 class="manage-boder">Manage Customer</h4>  -->
      <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/gin/index"> Manage GIN </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> {{ gin.SI_issueNum }}  </h6>
      
      
<div class="">

<div class="d-flex">
   
</div>


<div class="d-grid gap-2 d-md-flex justify-content-md-end">


<!-- <button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm  mb-1"> <router-link class="decoration" style="color: white;" to="#"> Print</router-link></button> -->


</div>
</div>
     
  
  
  <div class="table-responsive">
  <table style="font-size: small;" id=""  class="mt-2  table  table-striped table-hover ">
    
   
      
   
    <tbody>
     
      <!-- v-for="key in count" :key="key" -->
      <tr>
        <th style="width: 200px;">GIN No</th>
        <td >{{ gin.SI_issueNum }}</td>
      </tr>
      <tr>
        <th >Created Date</th>
        <td >{{ gin.SI_created_date }}</td>
      </tr>
      <tr>
        <th >Status</th>
        <td v-show="gin.SI_status== 1" >Confirmed</td>
      </tr>
      <tr>
        <th >Created By</th>
        <td >{{ gin.username }}</td>
      </tr>
        
       
       
     
      
      
      
    </tbody>
  </table>
  </div>
<br>
  <div class="card">
    <div class="card-body">



<h6 class="purchitem">GIN Item List</h6>

<span style="font-size: small;"><b>
  Total {{items.length}} items
</b></span>

<div class="table-responsive">
  <table style="font-size: small;" id=""  class="  table  table-striped table-hover table-bordered">
    <thead>
      <tr  >
        <th>#</th>
        <th>Product Code</th>
        <th>Qty</th>
        <th>Company/Description</th>
        <th>Serial No</th>
        <th>Location</th>
        <th>Item</th>
        <th>Mac</th>
        <th>Host Name</th>
        <th>Owner</th>
        <th>Installed_By</th>
        <th>Supplier</th>
        <th>Action</th>
        
       
        
       
      </tr>
    </thead>
   
      
   
    <tbody>
     
      <!-- v-for="key in count" :key="key" -->
      <!-- v-for="(item, index) in items" :key="item.idGrn"  -->
      <tr v-for="(item, index) in items" :key="item.idStockissuedetails" >
        <td>{{ index+1 }}</td>
        <td>{{item.P_code}}</td>
        <td>1</td>
        <td>{{item.description}}</td>
        <td>{{item.serial_no}}</td>
        <td>{{item.location_name}}</td>
        <td>{{item.item_name}}</td>
        <td>{{item.mac}}</td>
        <td>{{item.host_name}}</td>
        <td>{{item.Owner}}</td>
        <td>{{item.Installed_By}}</td>
        <td>{{item.sup_name}}</td>
        <td>
          <button  @click="UpdateGin(item.idStockissuedetails)" style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm mb-1"> Update</button>
        </td>
        
      
       
        
       <!--  <td ><span class="badge badge-danger border-radius0">Innactive</span></td> -->
       
     
      </tr>
      
      
    </tbody>
  </table>
  </div>






    </div>
  </div>
  
  
  
  
  </div>
  
  
  </template>
  
  <script>
   //import {   CButton, } from '@coreui/vue';
   //import httpClient from '@/axios';
   import axios from 'axios';
   import authService from '@/auth.service';
   import NavView from '../../components/NavView.vue'
    import SideBar from '../../components/SideBar.vue'
  
  
  
  export default {
      components:{ 
        NavView,SideBar
      },
  
      mounted(){
    this.loadGin()
      },
     
      computed: {
     
  
  
  
      },
  
      data(){
      return{
        
        gin:{},
        items:[],
      search: "",
        access_token:authService.isLoggedIn(),
       
        'idgin':this.$route.query.id,
          
  
        
    
       
      };
      },
  
      
  
  
     methods:{ 
  
      UpdateGin(id) {
        this.$router.push('/gin/update?id='+id);
       
    },
  
   
  
      async loadGin() {
      console.log(this.access_token);
      console.log(this.idgin);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/istockissue/view?id='+this.idgin,{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.gin = data.gin;
         this.items = data.item;
        
      } catch (error) {
        console.log(error.response);
      }
    },
     
  
   
  
  
  
  
  
  }
  
  
  
  
  };
  
  </script>
  
  <style scoped>

.purchitem{
    background-color: #0270ce6b;
    padding: 2px 2px 2px 8px;
    border-radius: 3px;
}
  .button1{
    width: 10px !important;
  }
  .border1{
    border: none;
    background-color: #0d6efd;
  }
  .font-color1:hover{
    color: black !important;
    cursor: pointer;
  }
  .manage-boder{
    border-bottom: 1px solid #E9E9E3;
    border-top: 1px solid #E9E9E3;
  }
  .border-radius0{
        border-radius:0px !important ;
  }
  .font1{
        font-size: 13px !important;
     
      }
      .font-bold1{
        font-weight: bold;
      }
      .manage-boder{
      border-bottom: 2px solid #E9E9E3;
      border-top: 2px solid #E9E9E3;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      
      }
      .font1{
        font-size: 13px !important;
     
      }
      .font-bold1{
        font-weight: bold !important;
      }
      .colr1{
          color:#A5A5A3;
          
      }
      .decoration{
      text-decoration: none;
      color:#A5A5A3  ;
    }
    .width7{
      width: 250px;
    }
  
  </style>