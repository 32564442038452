<template>

    <NavView></NavView>
        <SideBar></SideBar>
    
    <div class="container-fluid mb-5" >
    
      <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/grn/index"> Manage GRN </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Update GRN  </h6>
    
    <div class="container-fluid">
    <div class="card ">
    <div class="card-body">
    
    
    <form @submit.prevent="UpdateGrn()" >
    <h6 class="purchitem">Purchased Items</h6>
    <div class="table-responsive">
       
        <table style="font-size: small;" class="table table-responsive table-striped table-hover table-bordered">
            <thead>
                <tr>
                   
                    <th>   
                      <div class="form-group required">
                        <label> Product Code</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> Company/Description</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> Supplier</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> Serial No</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> PO</label>
                      </div>
                    </th>  
                  
                  
                </tr>
            </thead>
            <tbody>
                <tr >
                   
                    <td>
                        <input v-model="item.idGrnDetails" hidden>
                        <input class="form-control" style="font-size: small;" placeholder="Select Product" v-model="item.P_code" list="products" @input="loadDes(0)" required>
                        <datalist id="products" >
                           
                            <option v-for="product in products" :key="product" >
                                {{ product.P_code }}
                            </option>
                            
                      
                        </datalist>
                       
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="des.P_description" list="discription" placeholder="Select Description" @input="sugestProduct(0)" required>
                       <datalist id="discription"  >
                           
                           <option v-for="product in products" :key="product" >
                               {{ product.P_description }}
                           </option>
                           
                     
                       </datalist>
                    </td>
    
                    <td>
                        <input class="form-control" style="font-size: small;" placeholder="Select Supplier" v-model="item.sup_name" list="supllier" required>
                        <datalist id="supllier" >
                           
                            <option v-for="supplier in suppliers" :key="supplier" >
                                {{ supplier.sup_name }}
                            </option>
                            
                      
                        </datalist>
                        
                    </td>
                    
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.serial_no" required>
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.po" required>
                    </td>
                  
                    
                </tr>
    
    
             
            </tbody>
        </table>
    </div>
    
    <div class="navbar">
    
    <div class="d-flex  ">
    
    </div>
    <div class="d-flex  ">
        <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm">Update</button>
    </div>
    </div>
    
    
    </form>
    
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    
    </div>
    
    </template>
    
    <script>
     //import {   CButton, } from '@coreui/vue';
     //import httpClient from '@/axios';
     import axios from 'axios';
     import authService from '@/auth.service';
     import NavView from '../../components/NavView.vue'
      import SideBar from '../../components/SideBar.vue'
    
    
    
      export default {
    
        data(){
        return{
        
          access_token:authService.isLoggedIn(),
          userid:authService.isUserid(),
          suppliers:[],
         
          products:[],
         
    
         
          grn:{},
          item:{},
          des:[
            {},
          ],
          'idgrndetail':this.$route.query.id, 
          
      
         
        };
        },
        mounted(){
      this.loadSupplier();
      this.loadProduct();
      this.loadGrn(this.idgrndetail);
        },
        
        components:{ 
          NavView,SideBar
        },
    
        computed: {
            
          },
    
        methods:{ 
    
          async sugestProduct(){
                    console.log("amila");
                    console.log(this.des.P_description);
                    try {
              const { data} = await  axios.post('/sfu.evenware.com/web/api/mproduct/loadproduct',{des:this.des.P_description},{
                headers:{
                  'Authorization':'Bearer '+this.access_token,
                  'Content-type': 'application/json',
                 
                }
               });
               console.log(data);
               this.item = data.product;
              
              
            } catch (error) {
              console.log(error.response);
            }
                 
          },
          
          async loadDes(){
                    console.log("amila");
                    console.log(this.item.P_code);
                    try {
              const { data} = await  axios.post('/sfu.evenware.com/web/api/mproduct/loaddes',{product:this.item.P_code},{
                headers:{
                  'Authorization':'Bearer '+this.access_token,
                  'Content-type': 'application/json',
                 
                }
               });
               console.log(data);
               this.des = data.des;
              
              
            } catch (error) {
              console.log(error.response);
            }
                 
          },
    
    
    
           
          
    
            async UpdateGrn() {
        console.log(this.access_token);
        console.log(this.item);
        try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/igrn/edit1',{userid:this.userid,item:this.item},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           if(data.status == 200){
            this.$router.push('/grn/view?id='+data.Grn_id);
           }
          
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
      async loadGrn(id) {
        console.log(this.access_token);
        try {
          const { data} = await  axios.get('/sfu.evenware.com/web/api/igrn/edit?id='+id,{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log("grn last")
           console.log(data);
           this.item = data.item;
           this.des.P_description = data.item.P_description;
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
            async loadProduct() {
        console.log(this.access_token);
        try {
          const { data} = await  axios.get('/sfu.evenware.com/web/api/mproduct/sugestprodcut',{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.products = data.product;
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
    
    
            async loadSupplier() {
        console.log(this.access_token);
        try {
          const { data} = await  axios.get('/sfu.evenware.com/web/api/msupplier/sugestsupplier',{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.suppliers = data.supllier;
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
        }
      }
    
     </script> 
     <style scoped>
    .purchitem{
        background-color: #0270ce6b;
        padding: 2px 2px 2px 8px;
        border-radius: 3px;
    }
    
    /* apply styles for screens smaller than 768px */
    
    
    
    
    
    </style>