<template>
  <NavView></NavView>
    <SideBar></SideBar>
  <div class=""><br>
      <div class="container mrgg1 mrgg2">
          <div class="card ">
              <div class="card-body ">
                  <div class="cent1">
                  <h2 class="">Sign Up</h2>
                  
                  <div class=" bg-danger text-white ">{{errors}}</div>
                  <div class=" bg-danger text-white">{{errors2}}</div>
                  <div class=" bg-danger text-white">{{errors3}}</div>
                  <div class=" bg-success text-white">{{success}}</div>
                 
                  <!-- <div><img class=" mb-4" src="./../../assets/car1.png" alt="" width="150" > </div> -->
                      <br>
                  <!-- <form @submit="Signup()"> -->
                  <!-- <div class="group">      
                      <input class="input3" type="text" v-model="signup.username" required >
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="label1">Username</label>
                    </div> -->
                    <div>
                      <CFormLabel for="inputName4"> Username </CFormLabel>
                          <input class="width3 form-control" v-model="signup.username" type="text" />
                    </div>
                    <br>
                    <div>
                      <CFormLabel for="inputName4"> Password </CFormLabel>
                          <input class="width3 form-control" v-model="signup.password" type="password" />
                    </div><br>
                    <div>
                      <CFormLabel for="inputName4"> confirm Password </CFormLabel>
                          <input class="width3 form-control" v-model="signup.password_repeat" type="password" />
                    </div><br>

                    <!-- <div class="group">      
                      <input class="input3" type="password" v-model="signup.password" required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="label1">Password</label>
                    </div> -->

                    <!-- <div class="group">      
                      <input class="input3" type="password" v-model="signup.password_repeat" required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label class="label1">confirm Password</label>
                    </div> -->

                    <div class="mb-3">
                      <button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm  mb-1" v-on:click="Signup()">Signup </button>
                    </div>

                   

                   
                  
                  <!-- </form> -->
                  </div>
              </div>
          </div>
      </div>
      
  </div>

</template>
<script>
import axios from 'axios';
// import NavView2 from '../../components/NavView2.vue';
import {   CFormLabel  } from '@coreui/vue';
import NavView from '../../components/NavView.vue'
import SideBar from '../../components/SideBar.vue'

export default {
     
     data() {
   

     
 
         return {

          errors:'',
          errors2:'',
          errors3:'',

          'signup':{
            'username':'',
            'password':'',
            'password_repeat':'',
          },

          success:'',

         };

    },
    
components:{
CFormLabel, NavView,SideBar
},

methods: {
 async Signup() {
    // console.log("here we are",this.errorlist); check karaganimata awashya we
     console.log(this.signup);
     try {
     const {status, data} = await axios.post('/sfu.evenware.com/web/api/user/signup',this.signup);
    console.log(status,data);
    if(status == 200){
      this.success = "success";
      this.$router.push('/muser/index');
    }
    } catch (error) {
      console.log(error.response);
      this.errorsf = error.response.data.errors.username;
      this.errors2f = error.response.data.errors.password_repeat;
      this.errors3f = error.response.data.errors.password;
      if(this.errorsf){
        this.errors = error.response.data.errors.username[0];
      }
      if( this.errors2f){
        this.errors2 = error.response.data.errors.password_repeat[0];
      }
      if(this.errors3f){
        this.errors3 = error.response.data.errors.password[0];
      }
     
     }
    
       //response => {
       
        
       // this.error = response.data.errors
    //      //window.location.reload();
        
     //  }
     
  
      
 

  },


}

}



</script>
<style scoped>
.width3{
width: 80%;
margin-left: 10%;
}
.route3{
text-decoration: none;
font-size: 14px;
}
.color1{
  background-color: #E9E9E3;

 padding-bottom: 130px;
}
.mrgg1{
 width: 500px ;
 /* padding-bottom: 5px; */
}
@media only screen and (max-width: 768px) {
.mrgg2{
  width: 300px ;
}
}
.cent1{
  text-align: center !important;
}
.input1{
  border: none  !important;
}
* { box-sizing:border-box; }

/* basic stylings ------------------------------------------ */


/* form starting stylings ------------------------------- */
.group 			  { 
position:relative; 
margin-bottom:45px; 
margin-left: 16%;

}
.input3 				{
font-size:14px;
padding:10px 10px 10px 5px;
display:block;
width:80%;
border:none;
border-bottom:2px solid #999;
}
.input3:focus 		{ outline:none; }

/* LABEL ======================================= */
.label1 				 {
color:#999; 
font-size:16px;
font-weight:normal;
position:absolute;
pointer-events:none;
left:5px;
top:10px;
transition:0.2s ease all; 
-moz-transition:0.2s ease all; 
-webkit-transition:0.2s ease all;
}

/* active state */
.input3:focus ~ .label1, .input3:valid ~ .label1 		{
top:-20px;
font-size:16px;
color:#999;
}
.button1{
  border: 2px #0dcaf0 solid;
  
  padding-left: 12%;
  padding-right: 12%;
  
}





</style>