<template>
  <div  
         class="offcanvas offcanvas-start overflow-auto shadow my"
       
         tabindex="-1"
         data-bs-scroll="true"
         data-bs-backdrop="false"
         id="offcanvasExample"
         aria-labelledby="offcanvasExampleLabel"
       
       > 
       <div class="offcanvas-header">
         <h5 class="offcanvas-title" id="offcanvasExampleLabel"><img class="pfimg2" src="../assets/sfu-logo.png" alt="" width="110" > <span class=""></span></h5>
         
         <button type="button" class="btn-close text-reset ms-4" data-bs-dismiss="offcanvas" aria-label="Close"></button>
       </div>
       
       <div class="form-inline serch1 hiden1">
           <div class="input-group" data-widget="sidebar-search">
               
               <div class="input-group-append">
                  
               </div>
           </div>
       </div>
 
 
       <div class="offcanvas-body">
        
         
          
           <ul class="list-unstyled ps-0">
 
             <li class="mb-1 bodr1">
                 
                 <a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/">Manage Inventory</router-link></a>
                
               </li>
 
 
             <li class="mb-1 bodr1">
               <button class="btn btn-toggle d-inline-flex  rounded border-0 collapsed clr3" data-bs-toggle="collapse" data-bs-target="#dashbord-collapse" aria-expanded="false">
             
             
              <span href="/" class=""> GRN </span>
               </button>
               <div class="collapse mrgin1" id="dashbord-collapse">
                   <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ">
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/grn/index">Manage GRN</router-link></a></li>
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/grn/create">Create GRN</router-link></a></li>
                    
                     
                   </ul>
               </div>
             </li> 
 
 
               
               
               <li class="mb-1 bodr1">
                 <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed clr3" data-bs-toggle="collapse" data-bs-target="#payment-collapse" aria-expanded="false">
               
                  <span class=""> GIN </span>
                 </button>
                 <div class="collapse mrgin1" id="payment-collapse">
                   <ul class="btn-toggle-nav list-unstyled fw-normal pb-1">
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/gin/index">Manage GIN</router-link></a></li>
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/gin/create">Create GIN</router-link></a></li>
                    
                    
 
                   </ul>
                 </div>
               </li>
              
                 <li v-show="this.superadmin==1" class="mb-1 bodr1">
                   <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed clr3" data-bs-toggle="collapse" data-bs-target="#user-collapse" aria-expanded="false">
                      
                       <span class="">   Users </span> 
                   </button>
                   <div class="collapse mrgin1" id="user-collapse">
                     <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ">
                       <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/muser/index">Manage Users</router-link></a></li>
                       <!-- <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/user-management/role/index">Manage User Roles</router-link></a></li> -->
                       
                       
                     </ul>
                   </div>
                 </li>
        
               <li class="mb-1 bodr1">
                 <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed clr3" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
                 
                  <span class=""> Settings </span>
                 </button>
                 <div class="collapse mrgin1" id="account-collapse">
                   <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ">
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/location/index">Manage Location</router-link></a></li>
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/mitem/index">Manage Item</router-link></a></li>
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/msupplier/index">Manage Supplier</router-link></a></li>
                     <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded bodr2 clr3"><router-link class="router2" to="/mproduct/index">Manage Product</router-link></a></li>
                    
              
                    
                   </ul>
                 </div>
               </li>
             </ul>
           
 
       </div>
     </div>
 
 </template>
 <script>
 import authService from '@/auth.service';
 
  export default {
    
   data() {
       return {
 
         superadmin:authService.isSuperadmin(),
       
       };
     },
   };
 </script>
 <style >
 .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg:#ECF7FF;
}
.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: #ECF7FF;
}
 .form-group.required label:after {
   content: "*";
   color: red;
 }
 .pfimg1{
   margin-right: 20px;
   border-radius: 13px;
 }
 .pfimg2{
   margin-right: 20px;
 }
 .pfimg3{
   margin-right: 10px;
   border-radius: 13px;
 }
 .my {
       width: auto !important;
       padding-right: 25px ;
       height: auto;
       
 }
 .bodr1{
   border-bottom: 2px #E4E5E6 solid;
   padding: 5px;
 }
 .bodr2{
   
   padding: 10px;
 }
 .mrgin1{
       margin-left: 35px;       
 }
 
 .serch1{
   margin-left: 5px;
   margin-top: 10px;
 }
 .serch2{
   margin-right: 5px;
 }
 .router2{
   text-decoration: none;
   color: black;
 }
 .router2:hover{
   color: white;
 }
 .clr3:hover{
   background-color: rgb(71, 144, 255)  !important;
   width: 100% !important;
   color: white;
 }
 .clr3:focus{
   background-color: rgb(71, 144, 255) !important;
   width: 100%;
   color: white  !important; 
 }
 @media only screen and (max-width: 768px) {
 .hiden1{
   display: none;
 }
 }
 
 </style>