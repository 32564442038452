<template >
 
  <div class="container-fluid">
    <br>
      <div class="row top-mrgg1">
        <div class="col-md-6 ">
            <div class="left-mrgg1 mt-4">
            <img class="mb-2" src="../../assets/sfu-logo.png" width="125" alt="">
            <h6 style="font-size: large;">Welcome to SFU Intranet</h6>
            <p style="font-size: x-small;">
              Warning : All users of this system are traceable and any unauthorised usage is prohibited.
            </p>
            </div>
        </div>
        <div  class="col-md-6 margin2">
          <div class="bdr1 card ">
            <p class="authorization ">Authorization</p>

            <div class="">
              <input v-model="login.username" placeholder="Username" class="form-control input5 input6">
              <input type="password" v-model="login.password" placeholder="Password" class="form-control input5">
              <button v-on:click="Login()" style="background-color: rgb(71, 144, 255);color: white;" class="btn  login1">Login</button>
            </div>
          </div>
        </div>

      </div>
  </div>


</template>
<script>
//import axios from 'axios';
// import NavView2 from '../../components/NavView2.vue';
import authService from '../../auth.service';
//import {   CFormLabel  } from '@coreui/vue';
const date = new Date();
export default {

     data() {
   
      
     
 
         return {
        
          dateTime: {
      year : date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
     
    },
  
    timer: undefined,
          errors:'',
          errors2:'',
          'login':{
            'username':'',
            'password':'',
          }

         };

    },
components:{

},



methods: {
  setDateTime() {
    const date = new Date();
    this.dateTime = {
      year : date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
     
    };
  },
 

 
 

  async  Login() {
    // console.log("here we are",this.errorlist); check karaganimata awashya we
     console.log(this.login);
     const {success,errors,errors2} = await authService.Login(this.login);
     if(success){
      this.$router.push('/');
     }
     else{
      this.errors = errors;
      this.errors2 = errors2;
     }
     
     
        
        
    //      //window.location.reload();
        
      
      
 

  },


},
beforeMount() {
  this.timer = setInterval(this.setDateTime, 1000);
  
},
beforeUnmount() {
  clearInterval(this.timer);
},

}



</script>
<style scoped>

@media only screen and (min-width: 768px) {
.left-mrgg1{
margin-left: 30%;

}
.top-mrgg1{
margin-top: 140px
}


.bdr1{
padding: 30px 10px 60px 10px;
width: 408px;
margin-left: 7%;
}
.login1{
width: 365px;
font-size: small;
margin-left: 8px;
margin-top: 14px;
}
.input5{
width: 365px;
font-size: small;
margin-left: 8px;
margin-top: 8px;

}
.input6{
margin-top: 25px;
}
.authorization{
background-color: #f5f5f5;
width: 380px;
padding: 5px;
padding-left: 15px;
border-radius: 4px;
}

}







@media only screen and (max-width: 768px) {

.left-mrgg1{
text-align: center;
}

.bdr1{
padding: 30px 10px 50px 10px;
width: 340px;
margin: 0 auto;
}

.login1{
width: 300px;
font-size: small;
margin-left: 8px;
margin-top: 14px;
}

.input5{
width: 300px;
font-size: small;
margin-left: 8px;
margin-top: 8px;
}

.input6{
margin-top: 25px;
}

.authorization{
background-color: #f5f5f5;
width: 315px;
padding: 5px;
padding-left: 15px;
border-radius: 4px;
}

}









</style>