<template>

  <NavView></NavView>
      <SideBar></SideBar>
  
  <div class="container-fluid mb-5" >
  
    <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/grn/index"> Manage GRN </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Create GRN  </h6>
  
  <div class="container-fluid">
  <div class="card ">
  <div class="card-body">
  
  
  <form @submit.prevent="SaveGrn()" >
  <h6 class="purchitem">Purchased Items</h6>
  <div class="table-responsive">
     
      <table style="font-size: small;" class="table table-responsive table-striped table-hover table-bordered">
          <thead>
              <tr>
                  <th></th> 
                  <th>   
                    <div class="form-group required">
                      <label> Product Code</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> Company/Description</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> Supplier</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> Serial No</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> PO</label>
                    </div>
                  </th>  
                
                  <th></th>  
              </tr>
          </thead>
          <tbody>
              <tr >
                  <td></td>
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Product" v-model="item[0].P_code" list="products" @input="loadDes(0)" required>
                      <datalist id="products" >
                         
                          <option v-for="product in products" :key="product" >
                              {{ product.P_code }}
                          </option>
                          
                    
                      </datalist>
                     
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="des[0].P_description" list="discription" placeholder="Select Description" @input="sugestProduct(0)" required>
                     <datalist id="discription"  >
                         
                         <option v-for="product in products" :key="product" >
                             {{ product.P_description }}
                         </option>
                         
                   
                     </datalist>
                  </td>
  
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Supplier" v-model="item[0].sup_name" list="supllier" required>
                      <datalist id="supllier" >
                         
                          <option v-for="supplier in suppliers" :key="supplier" >
                              {{ supplier.sup_name }}
                          </option>
                          
                    
                      </datalist>
                      
                  </td>
                  
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].serial_no" required>
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].po" required>
                  </td>
                
                  <td><span @click="add" style="cursor: pointer;" ><i class="fa fa-plus" aria-hidden="true"></i></span></td>
              </tr>
  
  
              <tr v-for="key in count" :key="key">
                  <td><span @click="remove" style="cursor: pointer;" ><i class="fa fa-minus" aria-hidden="true"></i></span></td>
  
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Product" v-model="item[key].P_code" list="products" @input="loadDes(key)" required>
                      <datalist id="products" >
                         
                          <option v-for="product in products" :key="product" >
                              {{ product.P_code }}
                          </option>
                          
                    
                      </datalist>
                 
                  </td>
                  <td>
                    <input class="form-control" style="font-size: small;" v-model="des[key].P_description" list="discription" placeholder="Select Description" @input="sugestProduct(key)" required>
                     <datalist id="discription" >
                         
                         <option v-for="product in products" :key="product" >
                             {{ product.P_description }}
                         </option>
                         
                   
                     </datalist>
                  </td>
  
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Supplier" v-model="item[key].sup_name" list="supllier" required>
                      <datalist id="supllier" >
                         
                          <option v-for="supplier in suppliers" :key="supplier" >
                              {{ supplier.sup_name }}
                          </option>
                          
                    
                      </datalist>
                  </td>
                  
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].serial_no" required>
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].po" required>
                  </td>
             
                  <td><span @click="add" style="cursor: pointer;" ><i class="fa fa-plus" aria-hidden="true"></i></span></td>
              </tr>
          </tbody>
      </table>
  </div>
  
  <div class="navbar">
  
  <div class="d-flex  ">
  
  </div>
  <div class="d-flex  ">
      <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm">Create</button>
  </div>
  </div>
  
  
  </form>
  
  </div>
  </div>
  </div>
  
  
  
  
  
  
  
  
  </div>
  
  </template>
  
  <script>
   //import {   CButton, } from '@coreui/vue';
   //import httpClient from '@/axios';
   import axios from 'axios';
   import authService from '@/auth.service';
   import NavView from '../../components/NavView.vue'
    import SideBar from '../../components/SideBar.vue'
  
  
  
    export default {
  
      data(){
      return{
      
        access_token:authService.isLoggedIn(),
        userid:authService.isUserid(),
        suppliers:[],
       
        products:[],
       
  
        count: 0,
        grn:{},
        item:[
          {},
        ],
        des:[
          {},
        ],
        
        
    
       
      };
      },
      mounted(){
    this.loadSupplier();
    this.loadProduct();
      },
      
      components:{ 
        NavView,SideBar
      },
  
      computed: {
          
        },
  
      methods:{ 
  
        async sugestProduct(id){
                  console.log("amila");
                  console.log(this.des[id].P_description);
                  try {
            const { data} = await  axios.post('/sfu.evenware.com/web/api/mproduct/loadproduct',{des:this.des[id].P_description},{
              headers:{
                'Authorization':'Bearer '+this.access_token,
                'Content-type': 'application/json',
               
              }
             });
             console.log(data);
             this.item[id] = data.product;
            
            
          } catch (error) {
            console.log(error.response);
          }
               
        },
        
        async loadDes(id){
                  console.log("amila");
                  console.log(this.item[id].P_code);
                  try {
            const { data} = await  axios.post('/sfu.evenware.com/web/api/mproduct/loaddes',{product:this.item[id].P_code},{
              headers:{
                'Authorization':'Bearer '+this.access_token,
                'Content-type': 'application/json',
               
              }
             });
             console.log(data);
             this.des[id] = data.des;
            
            
          } catch (error) {
            console.log(error.response);
          }
               
        },
  
  
  
         
          add: function(){
              this.count++;
              this.item.push({
                
              },)
              this.des.push({
                
              },)
          },
  
          remove: function () {
              this.count--;
              this.item.splice({
                
              },1)
              this.des.splice({
                
              },1)
          },
  
          async SaveGrn() {
      console.log(this.access_token);
      console.log(this.grn);
      try {
        const { data} = await  axios.post('/sfu.evenware.com/web/api/igrn/create',{userid:this.userid,cont:this.count,grn:this.grn,item:this.item},{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.status = data.status;
         if(this.status == 200){
          this.$router.push('/grn/index');
         }
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
  
          async loadProduct() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/mproduct/sugestprodcut',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.products = data.product;
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
  
  
          async loadSupplier() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/msupplier/sugestsupplier',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.suppliers = data.supllier;
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
      }
    }
  
   </script> 
   <style scoped>
  .purchitem{
      background-color: #0270ce6b;
      padding: 2px 2px 2px 8px;
      border-radius: 3px;
  }
  
  /* apply styles for screens smaller than 768px */
  
  
  
  
  
  </style>