<template>

    <NavView></NavView>
        <SideBar></SideBar>
    
    <div class="container-fluid mb-5" >
    
      <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/mproduct/index"> Manage Product </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Create Product  </h6>
    
    <div class="container-fluid">
    <div class="card ">
    <div class="card-body">
    
    
    <form @submit.prevent="SaveProduct()">
    
   
      <div class="row">
        <div class="col-md-3">
          <div class="form-group required">
            <label style="font-size: small;">Product Code </label>
            <input v-model="product.P_code" style="font-size: small;" class="form-control" required>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group required">
            <label style="font-size: small;">Description </label>
            <input v-model="product.P_description" style="font-size: small;" class="form-control" required>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group required">
            <label style="font-size: small;">Item </label>
            <select style="font-size: small;" class="form-select" v-model="product.P_items" required>
                           
                <option v-for="item in items" :key="item" :value="item.id" >
                               {{ item.item_name }}
                </option>
                           
                     
            </select>
          </div>
        </div>
       

     </div>
        
 
    
    <div class="navbar">
    
    <div class="d-flex  ">
    
    </div>
    <div class="d-flex  ">
        <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn  btn-sm">Create</button>
    </div>
    </div>



</form>
    
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    
    </div>
    
    </template>
    
    <script>
     //import {   CButton, } from '@coreui/vue';
     //import httpClient from '@/axios';
     import axios from 'axios';
     import authService from '@/auth.service';
     import NavView from '../../components/NavView.vue'
      import SideBar from '../../components/SideBar.vue'
    
    
    
      export default {
    
        data(){
        return{
        
          access_token:authService.isLoggedIn(),
          userid:authService.isUserid(),
        
         
    
         
          items:{},
          product:{},
       
          
          
      
         
        };
        },
      
        mounted(){
    this.loadItem()
      },
        components:{ 
          NavView,SideBar
        },
    
        computed: {
            
          },
    
        methods:{ 
          
    
            async loadItem() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/mitems/index',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.items = data.mitem;
        
      } catch (error) {
        console.log(error.response);
      }
    },
     
           
    
            async SaveProduct() {
        console.log(this.access_token);
        console.log(this.sup);
    
        try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/mproduct/create',{userid:this.userid,product:this.product},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.status = data.status;
           if(this.status == 200){
            this.$router.push('/mproduct/index');
           }
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
    
        
    
    
    
        }
      }
    
     </script> 
     <style scoped>
    .purchitem{
        background-color: #0270ce6b;
        padding: 2px 2px 2px 8px;
        border-radius: 3px;
    }
    
    /* apply styles for screens smaller than 768px */
    
    
    
    
    
    </style>