<template>
  <div>
      
      <nav class="navbar navbar-expand navbar-light bg-light">
          <div class="container-fluid">
            <div class="navbar-nav ">
              <a><img class="pfimg2 hiden1"  src="../assets/sfu-logo.png" alt="" width="100" height="" ></a>
              <a class="nav-link mt-2"  id="navbarDropdownMenuLink" role="button" 
              data-bs-toggle="offcanvas" href="#offcanvasExample" aria-controls="offcanvasExample"  >
              <span class="menu1" >Menu</span>
                </a>
              </div>
    
    
             
            <div class="d-flex  ">
    
              <div class="dropdown bel1">
                <a
                  class="text-reset me-3  hidden-arrow "
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <i class="fa fa-commenting " aria-hidden="true"></i>
    
                  <span class="badge1 rounded-pill badge-notification bg-danger">1</span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">Some news</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Another news</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
    
    
           
              <div class="dropdown bel1">
                <a
                  class="text-reset me-3  hidden-arrow "
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <i class="fa fa-bell "></i>
                  <span class="badge1 rounded-pill badge-notification bg-danger">1</span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a class="dropdown-item" href="#">Some news</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Another news</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
    
              <img class="pf1 " src="../assets/pf.png" alt="" width="25" height="30" >
              <a class="btn btn-logout" href="" v-on:click="Logout()"> Logout({{user}}) </a>
            
            
            </div>
          </div>
        </nav>
        
  </div>
  </template>
  <script>
  import authService from '@/auth.service';
  import axios from 'axios';
  
  
    export default {
    
      
    
   
    
      data() {
        return {
          user:{},
          access_token:authService.isLoggedIn(),
  
        }
      },
      beforeMount(){
        this.user = authService.isUsername(); 
      },
      methods: {
        
        Logout() {
          console.log(this.access_token);
          axios.get('/web/api/user/logout',{
           headers:{
             'Authorization':'Bearer '+this.access_token,
             'Content-type': 'application/json',
            
           }
          }).then(
           response => {
             
             console.log(response);
          }
           
          ),
          authService.Logout();
        },
        
      },
    }
  
  </script>
  <style>
   
     
      .menu1{
          color: #77B7F7;
          border: 2px #77B7F7  solid; 
          padding: 8px 5px 10px 5px;
          border-radius: 5px;
          margin-left: 5px;
         
        }
        .menu1:hover{
          border: 4px #77B7F7  solid; 
         
        }
        .clr1{
          background-color: #AED8F2 !important;
          height:2000px !important;
          margin-left: -10px;
      
        }
      
        .bdr3{
         border-top: 2px #E4E5E6  solid;
         padding-bottom: 11px;
        }
        .shadow1 {
        /* border: 1px solid; */
        padding: 10px;
        /* box-shadow: 3px 6px 3px #bdbbbb; */
        }
      
        .click1:hover{
       
        background-color: blue;
        }
        .pf1{
          margin-right: 13px;
          margin-top: 3px;
        }
        .logout1{
          margin-right: 10px;
        }
        .pfimg2{
         
         margin-top: 4px;
         
         
        }
        .bel1{
          margin-right: 30px;
          margin-top: 8px;
        }
        .badge1 {
        position: absolute;
        top: -4px;
        right: 3px;
        padding: 1px 5px;
        border-radius: 50%;
        background-color: red;
        color: white;
        font-size: 10px !important;
   
        }
        .icon1{
          border-radius: 13px;
        }
        .router1{
          text-decoration: none;
        }
        
  </style>