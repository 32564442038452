<template>
  <NavView></NavView>
    <SideBar></SideBar>
<div class="container-fluid mb-5" >
    <!-- <h4 class="manage-boder">Manage Customer</h4>  -->
    <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Manage GRN  </h6>
    
    
    <div class="navbar">

<div class="d-flex mb-1">
  <input @input="PageNum(1)" style="font-size: small;" v-model="search" class="form-control me-2 width7" type="search" placeholder="Search" aria-label="Search">
</div>


<div class="d-grid gap-2 d-md-flex justify-content-md-end">


<button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm "> <router-link class="decoration" style="color: white;" to="/grn/create"> Create GRN</router-link></button>


</div>
</div>
   
<span style="font-size: small;"><b>
  Showing {{ currentPage*10-9 }}-<span v-show="currentPage*10<filteredGrns.length">{{ currentPage*10 }}</span><span v-show="currentPage*10>=filteredGrns.length">{{filteredGrns.length}}</span> of {{filteredGrns.length}} items
</b></span>

<div class="table-responsive">
<table style="font-size: small;" id=""  class="  table  table-striped table-hover table-bordered">
  <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>GRN No</th>
      <th>Status</th>
      <th>Created By</th>
      <th>Action</th>
     
      
     
    </tr>
  </thead>
 
    
 
  <tbody>
   
    <!-- v-for="key in count" :key="key" -->
    <tr  v-for="(grn, index) in paginatedItems" :key="grn.idGrn" >
      <th >{{index+1}}</th>
      <th class="d-none"> {{grn.idGrn}} </th>
      <td>{{grn.G_created_date}}</td>
      <td>{{grn.G_grn_num}}</td>
      <td v-show="grn.G_status==1">Confirmed</td>
      <td>{{grn.username}}</td>
      <td> <button @click="ViewGrn(grn.idGrn)" style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm mb-1">  View</button>
      </td>
     
      
     <!--  <td ><span class="badge badge-danger border-radius0">Innactive</span></td> -->
     
   
    </tr>
    
    
  </tbody>
</table>
</div>



<nav v-show="totalPages > 1" aria-label="Page navigation example">
<ul class="pagination">

  <li class="page-item" >
    <button class="page-link "  aria-label="Previous" type="button" :disabled="currentPage === 1" @click="currentPage--">
      <span  aria-hidden="true" >&laquo;</span>
    </button>
  </li>

  <li v-for="(totalPage , index) in totalPages" :key="totalPage" class="page-item"
   v-bind:class="{ active: currentPage==index+1 }" >
    <a type="button" @click="PageNum(index+1)" class="page-link" >{{ index+1 }}</a>
  </li>

  <li class="page-item" >
    <button class="page-link" type="button" :disabled="currentPage === totalPages" 
        @click="currentPage++"   aria-label="Next">
      <span aria-hidden="true" >&raquo;</span>
    </button>
  </li>

</ul>
</nav>





</div>


</template>

<script>
 //import {   CButton, } from '@coreui/vue';
 //import httpClient from '@/axios';
 import axios from 'axios';
 import authService from '@/auth.service';
 import NavView from '../../components/NavView.vue'
  import SideBar from '../../components/SideBar.vue'



export default {
    components:{ 
      NavView,SideBar
    },

    mounted(){
  this.loadMiinventory()
    },
   
    computed: {

      totalPages() {
  
    return Math.ceil(this.filteredGrns.length / this.itemsPerPage);
  },





      filteredGrns() {
      return  this.grns.filter((grn) => {
        return (
          grn.G_grn_num.toLowerCase().includes(this.search.toLowerCase()) ||
          grn.G_created_date.toLowerCase().includes(this.search.toLowerCase()) ||
          grn.username.toLowerCase().includes(this.search.toLowerCase()) 
             
          
        );
      });
     
    },
    paginatedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredGrns.slice(startIndex, endIndex);
  },


    },

    data(){
    return{
      
      grns:[],
    search: "",
      access_token:authService.isLoggedIn(),
     
      currentPage: 1,
      itemsPerPage: 10
        

      
  
     
    };
    },

    


   methods:{ 

    PageNum(id){
   
   this.currentPage = id;
 },

 

    async loadMiinventory() {
    console.log(this.access_token);
    try {
      const { data} = await  axios.get('/sfu.evenware.com/web/api/igrn/index',{
        headers:{
          'Authorization':'Bearer '+this.access_token,
          'Content-type': 'application/json',
         
        }
       });
       console.log(data);
       this.grns = data.grn;
      
    } catch (error) {
      console.log(error.response);
    }
  },
   
   ViewGrn(id) {
      this.$router.push('/grn/view?id='+id);
     
  },
   

 





}




};

</script>

<style scoped>
.button1{
  width: 10px !important;
}
.border1{
  border: none;
  background-color: #0d6efd;
}
.font-color1:hover{
  color: black !important;
  cursor: pointer;
}
.manage-boder{
  border-bottom: 1px solid #E9E9E3;
  border-top: 1px solid #E9E9E3;
}
.border-radius0{
      border-radius:0px !important ;
}
.font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold;
    }
    .manage-boder{
    border-bottom: 2px solid #E9E9E3;
    border-top: 2px solid #E9E9E3;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    
    }
    .font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold !important;
    }
    .colr1{
        color:#A5A5A3;
        
    }
    .decoration{
    text-decoration: none;
    color:#A5A5A3  ;
  }
  .width7{
    width: 250px;
  }

</style>