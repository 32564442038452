<template>
  <NavView></NavView>
    <SideBar></SideBar>
<div class="container-fluid mb-5" >
    <!-- <h4 class="manage-boder">Manage Customer</h4>  -->
    <h6 class="manage-boder pt-2 font1 font-bold1 ps-1"> <router-link class="decoration" to="/"> Manage Inventory </router-link></h6>
 
<div class="navbar">

<div class="d-flex mb-1">
  <input @input="PageNum(1)" style="font-size: small;" v-model="search" class="form-control me-2 width7" type="search" placeholder="Search" aria-label="Search">
</div>


<div class="d-grid gap-2 d-md-flex justify-content-md-end">
<button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm  "> <router-link class="decoration" style="color: white;" to="/grn/create"> Create GRN</router-link></button>
<button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm  "> <router-link class="decoration" style="color: white;" to="/gin/create"> Create GIN</router-link></button>
</div>

</div>
   


<span style="font-size: small;"><b>
  Showing {{ currentPage*10-9 }}-<span v-show="currentPage*10<filteredUsers.length">{{ currentPage*10 }}</span><span v-show="currentPage*10>=filteredUsers.length">{{filteredUsers.length}}</span> of {{filteredUsers.length}} items
</b></span>

<div class="table-responsive">
<table style="font-size: small;" id=""  class="  table  table-striped table-hover table-bordered">
  <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Supplier</th>
      <th>PO</th>
      <th>Qty</th>
      <th>Product Code</th>
      <th>Company/Description</th>
      <th>Location</th>
      <th>Serial No</th>
      <th>Host Name</th>
      <th>Mac</th>
      <th>Owner</th>
      <th>Installed By</th>
      <th>Date Out</th>
      
     
    </tr>
  </thead>
 
    
 
  <tbody>
   
    <!-- v-for="key in count" :key="key" -->
    <tr  v-for="(miinventory, index) in paginatedItems" :key="miinventory.id" >
      <th >{{index+1}}</th>
      <th class="d-none"> {{miinventory.idInventory}} </th>
      <td>{{miinventory.I_created_date}}</td>
      <td>{{miinventory.sup_name}}</td>
      <td>{{miinventory.po}}</td>
      <td>{{miinventory.I_received_qty}}</td>
      <td>{{miinventory.I_pcode}}</td>
      <td>{{miinventory.i_pdescription}}</td>
      <td>{{miinventory.location_name}}</td>
      <td>{{miinventory.i_serial_no}}</td>
      <td>{{miinventory.host_name}}</td>
      <td>{{miinventory.mac}}</td>
      <td>{{miinventory.Owner}}</td>
      <td>{{miinventory.Installed_By}}</td>
      <td>{{miinventory.Date_Out}}</td>
      
     <!--  <td ><span class="badge badge-danger border-radius0">Innactive</span></td> -->
     
   
    </tr>
    
    
  </tbody>
</table>
</div>
<!-- <div class="pagination">
      <button :disabled="currentPage === 1" @click="currentPage--" class="btn btn-primary btn-sm font1 me-2" >Previous</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button :disabled="currentPage === totalPages" @click="currentPage++" class="btn btn-primary btn-sm font1 ms-2">Next</button>
</div> -->

<nav v-show="totalPages > 1" aria-label="Page navigation example">
  <ul class="pagination">

    <li class="page-item" >
      <button class="page-link "  aria-label="Previous" type="button" :disabled="currentPage === 1" @click="currentPage--">
        <span  aria-hidden="true" >&laquo;</span>
      </button>
    </li>

    <li v-for="(totalPage , index) in totalPages" :key="totalPage" class="page-item"
     v-bind:class="{ active: currentPage==index+1 }" >
      <a type="button" @click="PageNum(index+1)" class="page-link" >{{ index+1 }}</a>
    </li>
  
    <li class="page-item" >
      <button class="page-link" type="button" :disabled="currentPage === totalPages" 
          @click="currentPage++"   aria-label="Next">
        <span aria-hidden="true" >&raquo;</span>
      </button>
    </li>

  </ul>
</nav>











</div>


</template>

<script>
 //import {   CButton, } from '@coreui/vue';
 //import httpClient from '@/axios';
 import axios from 'axios';
 import authService from '@/auth.service';
 import NavView from '../../components/NavView.vue'
  import SideBar from '../../components/SideBar.vue'



export default {
    components:{ 
      NavView,SideBar
    },

    mounted(){
  this.loadMiinventory()
    },
   
    computed: {

      totalPages() {
      
      return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
    },

      filteredUsers() {
      return  this.miinventorys.filter((miinventory) => {
        return (
          miinventory.idInventory.toLowerCase().includes(this.search.toLowerCase()) ||
          miinventory.I_created_date.toLowerCase().includes(this.search.toLowerCase()) ||
          miinventory.I_pcode.toLowerCase().includes(this.search.toLowerCase()) ||
          miinventory.sup_name.toLowerCase().includes(this.search.toLowerCase()) ||
          miinventory.po.toLowerCase().includes(this.search.toLowerCase()) ||
          miinventory.I_received_qty.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.i_pdescription.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.location_name.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.i_serial_no.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.host_name.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.mac.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.Owner.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.Installed_By.toLowerCase().includes(this.search.toLowerCase()) ||        
          miinventory.Date_Out.toLowerCase().includes(this.search.toLowerCase())         
          
        );
      });
     
     
    },

    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredUsers.slice(startIndex, endIndex);
    },



    },

    data(){
    return{
      
    miinventorys:[],
    search: "",
      access_token:authService.isLoggedIn(),
     
     
      currentPage: 1,
        itemsPerPage: 10

      
  
     
    };
    },

    


   methods:{ 

   


    PageNum(id){
     
      this.currentPage = id;
    },

 

    async loadMiinventory() {
    console.log(this.access_token);
    try {
      const { data} = await  axios.get('/sfu.evenware.com/web/api/iinventory/index',{
        headers:{
          'Authorization':'Bearer '+this.access_token,
          'Content-type': 'application/json',
         
        }
       });
       console.log(data);
       this.miinventorys = data.inventory;
      
    } catch (error) {
      console.log(error.response);
    }
  },
   

 





}




};

</script>

<style scoped>

.button1{
  width: 10px !important;
}
.border1{
  border: none;
  background-color: #0d6efd;
}
.font-color1:hover{
  color: black !important;
  cursor: pointer;
}
.manage-boder{
  border-bottom: 1px solid #E9E9E3;
  border-top: 1px solid #E9E9E3;
}
.border-radius0{
      border-radius:0px !important ;
}
.font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold;
    }
    .manage-boder{
    border-bottom: 2px solid #E9E9E3;
    border-top: 2px solid #E9E9E3;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    
    }
    .font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold !important;
    }
    .colr1{
        color:#A5A5A3;
        
    }
    .decoration{
    text-decoration: none;
    color:#A5A5A3  ;
  }
  .width7{
    width: 250px;
  }

</style>