import { createRouter, createWebHistory } from 'vue-router'
import DashbordIndex from '../components/HomeDashbord/DashbordIndex.vue'
import DashbordIndex2 from '../components/HomeDashbord/DashbordIndex2.vue'
import LoginView from '../components/login/LoginView.vue'
import SignupView from '../components/login/SignupView.vue'
import MuserIndex from '../components/login/MuserIndex.vue'
import GrnCreate from '../components/grn/GrnCreate.vue'
import GrnIndex from '../components/grn/GrnIndex.vue'
import GrnView from '../components/grn/GrnView.vue'
import GrnUpdate from '../components/grn/GrnUpdate.vue'
import GinCreate from '../components/gin/GinCreate.vue'
import GinIndex from '../components/gin/GinIndex.vue'
import GinView from '../components/gin/GinView.vue'
import GinUpdate from '../components/gin/GinUpdate.vue'
import LocationIndex from '../components/location/LocationIndex.vue'
import LocationCreate from '../components/location/LocationCreate.vue'
import LocationUpdate from '../components/location/LocationUpdate.vue'
import MitemIndex from '../components/mitem/MitemIndex.vue'
import MitemCreate from '../components/mitem/MitemCreate.vue'
import MitemUpdate from '../components/mitem/MitemUpdate.vue'
import MsupplierIndex from '../components/msupplier/MsupplierIndex.vue'
import MsupplierCreate from '../components/msupplier/MsupplierCreate.vue'
import MsupplierUpdate from '../components/msupplier/MsupplierUpdate.vue'
import MproductIndex from '../components/mproduct/MproductIndex.vue'
import MproductCreate from '../components/mproduct/MproductCreate.vue'
import MproductUpdate from '../components/mproduct/MproductUpdate.vue'



import MiinventoryIndex from '../components/miinventory/MiinventoryIndex.vue'





import TestView from '../components/TestView.vue'
import TestView2 from '../components/TestView2.vue'
import authService from '@/auth.service'


const routes = [
 
  {
    path: '/dashbord',
    name: 'DashbordIndex',
    component: DashbordIndex,
    
  },
  {
    path: '/2',
    name: 'DashbordIndex2',
    component: DashbordIndex2,
    
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/test',
    name: 'TestView',
    component: TestView
  },
  {
    path: '/test2',
    name: 'TestView2',
    component: TestView2
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/signup',
    name: 'SignupView',
    component: SignupView
  },
  {
    path: '/muser/index',
    name: 'MuserIndex',
    component: MuserIndex
  },
  
 
 
  {
    path: '/',
    name: 'MiinventoryIndex',
    component: MiinventoryIndex
  },
  {
    path: '/grn/create',
    name: 'GrnCreate',
    component: GrnCreate
  },
  {
    path: '/grn/index',
    name: 'GrnIndex',
    component: GrnIndex
  },
  {
    path: '/grn/view',
    name: 'GrnView',
    component: GrnView
  },
  {
    path: '/grn/update',
    name: 'GrnUpdate',
    component: GrnUpdate
  },
  {
    path: '/gin/create',
    name: 'GinCreate',
    component: GinCreate
  },
  {
    path: '/gin/index',
    name: 'GinIndex',
    component: GinIndex
  },
  {
    path: '/gin/view',
    name: 'GinView',
    component: GinView
  },
  {
    path: '/gin/update',
    name: 'GinUpdate',
    component: GinUpdate
  },
  {
    path: '/location/index',
    name: 'LocationIndex',
    component: LocationIndex
  },
  {
    path: '/location/create',
    name: 'LocationCreate',
    component: LocationCreate
  },
  {
    path: '/location/update',
    name: 'LocationUpdate',
    component: LocationUpdate
  },
  {
    path: '/mitem/index',
    name: 'MitemIndex',
    component: MitemIndex
  },
  {
    path: '/mitem/create',
    name: 'MitemCreate',
    component: MitemCreate
  },
  {
    path: '/mitem/update',
    name: 'MitemUpdate',
    component: MitemUpdate
  },
  {
    path: '/msupplier/index',
    name: 'MsupplierIndex',
    component: MsupplierIndex
  },
  {
    path: '/msupplier/Create',
    name: 'MsupplierCreate',
    component: MsupplierCreate
  },
  {
    path: '/msupplier/Update',
    name: 'MsupplierUpdate',
    component: MsupplierUpdate
  },
  {
    path: '/mproduct/index',
    name: 'MproductIndex',
    component: MproductIndex
  },
  {
    path: '/mproduct/create',
    name: 'MproductCreate',
    component: MproductCreate
  },
  {
    path: '/mproduct/Update',
    name: 'MproductUpdate',
    component: MproductUpdate
  },

 
 
 
 
 
  
  
 
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  base: 'vuejs/dist',
  routes
});


router.beforeEach((to , from , next) =>{
console.log(to,from);
  
  if(to.name !== 'LoginView' &&  !authService.isLoggedIn()){
    next({name: 'LoginView'})
  }else if(authService.isLoggedIn() && to.name === 'LoginView'){
    next({name: 'home'})
  }else{
    next()
  }

  
   
if(authService.isLoggedIn()){

 
  setTimeout(myGreeting, 14400000);
  
}
function myGreeting() {
  localStorage.removeItem('ACCESS_TOKEN');
  window.location.href = "/login";
 
}


 
 
});

export default router
