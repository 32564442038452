<template>
 
  <router-view/>
  <FooterView ></FooterView>
</template>
<script>
  
  import FooterView from './components/FooterView.vue'
  

  export default{
        components:{
         FooterView
        }
    }


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
}



nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}



</style>
