<template>
  <NavView></NavView>
    <SideBar></SideBar>
<div class="container-fluid mb-5" >
    <!-- <h4 class="manage-boder">Manage Customer</h4>  -->
    <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Manage GIN  </h6>
    
    
    <div class="navbar">

<div class="d-flex mb-1">
  <input @input="PageNum(1)" style="font-size: small;" v-model="search" class="form-control me-2 width7" type="search" placeholder="Search" aria-label="Search">
</div>


<div class="d-grid gap-2 d-md-flex justify-content-md-end">


<button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm"> <router-link class="decoration" style="color: white;" to="/gin/create"> Create GIN</router-link></button>


</div>
</div>
   
<span style="font-size: small;"><b>
  Showing {{ currentPage*10-9 }}-<span v-show="currentPage*10<filteredGins.length">{{ currentPage*10 }}</span><span v-show="currentPage*10>=filteredGins.length">{{filteredGins.length}}</span> of {{filteredGins.length}} items
</b></span>

<div class="table-responsive">
<table style="font-size: small;" id=""  class="  table  table-striped table-hover table-bordered">
  <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>GIN No</th>
      <th>Status</th>
      <th>Created By</th>
      <th>Action</th>
     
      
     
    </tr>
  </thead>
 
    
 
  <tbody>
   
    <!-- v-for="key in count" :key="key" -->
    <tr  v-for="(gin, index) in paginatedItems" :key="gin.idStockIssue" >
      <th >{{index+1}}</th>
      <th class="d-none"> {{gin.idStockIssue}} </th>
      <td>{{gin.SI_created_date}}</td>
      <td>{{gin.SI_issueNum}}</td>
      <td v-show="gin.SI_status==1">Confirmed</td>
      <td>{{gin.username}}</td>
      <td> <button @click="ViewGin(gin.idStockIssue)" style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm mb-1">  View</button></td>
     
      
     <!--  <td ><span class="badge badge-danger border-radius0">Innactive</span></td> -->
     
   
    </tr>
    
    
  </tbody>
</table>
</div>



<nav v-show="totalPages > 1" aria-label="Page navigation example">
<ul class="pagination">

<li class="page-item" >
  <button class="page-link "  aria-label="Previous" type="button" :disabled="currentPage === 1" @click="currentPage--">
    <span  aria-hidden="true" >&laquo;</span>
  </button>
</li>

<li v-for="(totalPage , index) in totalPages" :key="totalPage" class="page-item"
 v-bind:class="{ active: currentPage==index+1 }" >
  <a type="button" @click="PageNum(index+1)" class="page-link" >{{ index+1 }}</a>
</li>

<li class="page-item" >
  <button class="page-link" type="button" :disabled="currentPage === totalPages" 
      @click="currentPage++"   aria-label="Next">
    <span aria-hidden="true" >&raquo;</span>
  </button>
</li>

</ul>
</nav>







</div>


</template>

<script>
 //import {   CButton, } from '@coreui/vue';
 //import httpClient from '@/axios';
 import axios from 'axios';
 import authService from '@/auth.service';
 import NavView from '../../components/NavView.vue'
  import SideBar from '../../components/SideBar.vue'



export default {
    components:{ 
      NavView,SideBar
    },

    mounted(){
  this.loadGin()
    },
   
    computed: {

      totalPages() {
return Math.ceil(this.filteredGins.length / this.itemsPerPage);
},


      filteredGins() {
      return  this.gins.filter((gin) => {
        return (
          gin.SI_issueNum.toLowerCase().includes(this.search.toLowerCase()) ||
          gin.SI_created_date.toLowerCase().includes(this.search.toLowerCase()) ||
          gin.username.toLowerCase().includes(this.search.toLowerCase()) 
             
          
        );
      });
     
    },

    paginatedItems() {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
  return this.filteredGins.slice(startIndex, endIndex);
},

    },

    data(){
    return{
      
      gins:[],
    search: "",
      access_token:authService.isLoggedIn(),
     
     
      currentPage: 1,
    itemsPerPage: 10

      
  
     
    };
    },

    


   methods:{ 



    PageNum(id){
 
 this.currentPage = id;
},
 

    async loadGin() {
    console.log(this.access_token);
    try {
      const { data} = await  axios.get('/sfu.evenware.com/web/api/istockissue/index',{
        headers:{
          'Authorization':'Bearer '+this.access_token,
          'Content-type': 'application/json',
         
        }
       });
       console.log(data);
       this.gins = data.gin;
      
    } catch (error) {
      console.log(error.response);
    }
  },
   
   ViewGin(id) {
      this.$router.push('/gin/view?id='+id);
     
  },
   

 





}




};

</script>

<style scoped>
.button1{
  width: 10px !important;
}
.border1{
  border: none;
  background-color: #0d6efd;
}
.font-color1:hover{
  color: black !important;
  cursor: pointer;
}
.manage-boder{
  border-bottom: 1px solid #E9E9E3;
  border-top: 1px solid #E9E9E3;
}
.border-radius0{
      border-radius:0px !important ;
}
.font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold;
    }
    .manage-boder{
    border-bottom: 2px solid #E9E9E3;
    border-top: 2px solid #E9E9E3;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    
    }
    .font1{
      font-size: 13px !important;
   
    }
    .font-bold1{
      font-weight: bold !important;
    }
    .colr1{
        color:#A5A5A3;
        
    }
    .decoration{
    text-decoration: none;
    color:#A5A5A3  ;
  }
  .width7{
    width: 250px;
  }

</style>