<template>

  <NavView></NavView>
      <SideBar></SideBar>
  
  <div class="container-fluid mb-5" >
  
      <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/gin/index"> Manage GIN </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Create GIN  </h6>
  
  <div class="container-fluid">
  <div class="card ">
  <div class="card-body">
  
  <form @submit.prevent="SaveGin()">
  
  <h6 class="purchitem">Available Items for GIN</h6>
  <div class="table-responsive">
     
      <table style="font-size: small;" class="table table-responsive table-striped table-hover table-bordered">
          <thead>
              <tr>
                  <th></th>
                  <th>
                    <div class="form-group required">
                      <label> Serial No</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> Location</label>
                    </div>
                  </th>
                  <th>
                    <div class="form-group required">
                      <label> Company/Description</label>
                    </div>
                  </th>
                  <th>Host Name</th>  
                  <th>Mac</th>  
                  <th>Owner</th>  
                  <th>Installed By</th>  
                
                  <th></th>  
              </tr>
          </thead>
          <tbody>
              <tr >
                  <td></td>
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Serial No" v-model="item[0].serial_no" @input="loadDes(0)" list="serial" required>
                      <datalist id="serial" >
                         
                          <option v-for="serial in serials" :key="serial" >
                              {{ serial.i_serial_no }}
                          </option>
                          
                    
                      </datalist>
                     
                  </td>
  
  
                  <td>
                      
                      <select style="font-size: small;" class="form-select" v-model="item[0].location" required>
                    
                         <option v-for="location in locations" :key="location" :value="location.id_location" >
                             {{ location.location_name }}
                         </option>
                         
                   
                     </select>
                      
                  </td>
                  
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="des[0].i_pdescription" list="Description" placeholder="Select Description" @input="loadSeri(0)" required>
                     <datalist id="Description" >
                         
                         <option v-for="serial in serials" :key="serial" >
                             {{ serial.i_pdescription }}
                         </option>
                         
                   
                     </datalist>
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].host_name">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].mac">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].owner">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[0].installed">
                  </td>
                
                  <td><span @click="add" style="cursor: pointer;" ><i class="fa fa-plus" aria-hidden="true"></i></span></td>
              </tr>
  
  
              <tr v-for="key in count" :key="key">
                  <td><span @click="remove" style="cursor: pointer;" ><i class="fa fa-minus" aria-hidden="true"></i></span></td>
  
                  <td>
                      <input class="form-control" style="font-size: small;" placeholder="Select Serial No" v-model="item[key].serial_no" list="serial" @input="loadDes(key)" required>
                      <datalist id="serial" >
                         
                         <option v-for="serial in serials" :key="serial" >
                             {{ serial.i_serial_no }}
                         </option>
                         
                   
                     </datalist>
                 
                  </td>
  
  
                  <td>
                      <select style="font-size: small;" class="form-select" v-model="item[key].location"  required>
                        
                         <option v-for="location in locations" :key="location" :value="location.id_location" >
                             {{ location.location_name }}
                         </option>
                         
                   
                     </select>
                  </td>
                  
                  <td>
                <input class="form-control" style="font-size: small;" v-model="des[key].i_pdescription" list="Description" placeholder="Select Description" @input="loadSeri(key)" required>
                <datalist id="Description" >
                         
                         <option v-for="serial in serials" :key="serial" >
                             {{ serial.i_pdescription }}
                         </option>
                         
                   
                     </datalist>
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].host_name">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].mac">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].owner">
                  </td>
                  <td>
                     <input class="form-control" style="font-size: small;" v-model="item[key].installed">
                  </td>
                
             
                  <td><span @click="add" style="cursor: pointer;" ><i class="fa fa-plus" aria-hidden="true"></i></span></td>
              </tr>
          </tbody>
      </table>
  </div>
  
  <div class="navbar">
  
  <div class="d-flex  ">
  
  </div>
  <div class="d-flex  ">
      <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn  ">Create</button>
  </div>
  </div>
  

</form>
  </div>
  </div>
  </div>
  
  
  

  
  
  
  
  </div>
  
  </template>
  
  <script>
   //import {   CButton, } from '@coreui/vue';
   //import httpClient from '@/axios';
   import axios from 'axios';
   import authService from '@/auth.service';
   import NavView from '../../components/NavView.vue'
    import SideBar from '../../components/SideBar.vue'
  
  
  
    export default {
  
      data(){
      return{
      
        access_token:authService.isLoggedIn(),
        userid:authService.isUserid(),
        locations:[],
       
        serials:[],
       
  
        count: 0,
    
        item:[
          {},
        ],
  
        mynew:'',
        des:[
          {},
        ],
        
    
       
      };
      },
      mounted(){
    this.loadLocation();
    this.loadSerial();
      },
      
      components:{ 
        NavView,SideBar
      },
  
      computed: {
          
        },
  
      methods:{ 
        

        async loadSeri(id){
              console.log(id);
              console.log(this.des[id].i_pdescription);
             
              try {
        const { data} = await  axios.post('/sfu.evenware.com/web/api/iinventory/loadseri',{des:this.des[id].i_pdescription},{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
          this.item[id].serial_no = data.seri.i_serial_no;
          this.item[id].location = data.seri.Inventory_location;
        
        
      } catch (error) {
        console.log(error.response);
      }
           
    },
  
        async loadDes(id){
              console.log("amila");
            
              try {
        const { data} = await  axios.post('/sfu.evenware.com/web/api/iinventory/loaddes',{serial:this.item[id].serial_no},{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.des[id] = data.des;
         this.item[id].location = data.des.Inventory_location;
        
        
      } catch (error) {
        console.log(error.response);
      }
           
    },
          add: function(){
              this.count++;
              this.item.push({
                
              },)
              this.des.push({
                
              },)
          },
  
          remove: function () {
              this.count--;
              this.des.splice({
                
              },1)
              this.item.splice({
                
              },1)
          },
  
          async SaveGin() {
      console.log(this.access_token);
      console.log(this.item);
  
      try {
        const { data} = await  axios.post('/sfu.evenware.com/web/api/istockissue/create',{userid:this.userid,cont:this.count,item:this.item},{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.status = data.status;
         if(this.status == 200){
          this.$router.push('/gin/index');
         }
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
  
          async loadSerial() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/iinventory/sugestserial',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.serials = data.serial;
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
  
  
          async loadLocation() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/mlocation/sugestlocation',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.locations = data.location;
        
      } catch (error) {
        console.log(error.response);
      }
    },
  
  
  
      }
    }
  
   </script> 
   <style scoped>
  .purchitem{
      background-color: #0270ce6b;
      padding: 2px 2px 2px 8px;
      border-radius: 3px;
  }
  
  /* apply styles for screens smaller than 768px */
  
  
  
  
  
  </style>