<template>
    <NavView></NavView>
    <SideBar></SideBar>
    <div class="container-fluid ">
        <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Dashbord </router-link><span class="pfimg1"></span>   </h6>
    <div class="card" >
        <div class="card-body">
            <div class="row mb-5">
                <div class="col-md-6">
                    <Bar
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                    :chart-id="chartId"
                    :dataset-id-key="datasetIdKey"
                    :plugins="plugins"
                    :css-classes="cssClasses"
                    :styles="styles"
                    :width="width"
                    :height="height"/>
                </div>
                <div class="col-md-6">

                </div>
            </div>
            






        </div>
    </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  import NavView from '../../components/NavView.vue'
  import SideBar from '../../components/SideBar.vue'
  import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'BarChart',
    components: {
      NavView,SideBar,Bar
    },
    props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
        chartData: {
        labels: [ 'January', 'February', 'March','april','May','June','July','August','September','October','November','December' ],
        datasets: [ { data: [50, 20, 12, 40, 20, 5, 25, 35, 10, 40, 15, 45] } ]
      },
      chartOptions: {
        responsive: true
      }
    }
  },
  }
  </script>
  <style>
      .manage-boder{
  border-bottom: 2px solid #E9E9E3;
  border-top: 2px solid #E9E9E3;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  
  }

  .font1{
  font-size: 13px !important;

}
.font-bold1{
  font-weight: bold;
}
.decoration{
  text-decoration: none;
  color:#A5A5A3  ;
}
  </style>
  