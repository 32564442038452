<template>

    <NavView></NavView>
        <SideBar></SideBar>
    
    <div class="container-fluid mb-5" >
    
        <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/gin/index"> Manage GIN </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Update GIN  </h6>
    
    <div class="container-fluid">
    <div class="card ">
    <div class="card-body">
    
    <form @submit.prevent="UpdateGin()">
    
    <h6 class="purchitem">Available Items for GIN</h6>
    <div class="table-responsive">
       
        <table style="font-size: small;" class="table table-responsive table-striped table-hover table-bordered">
            <thead>
                <tr>
                  
                    <th>
                      <div class="form-group required">
                        <label> Serial No</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> Location</label>
                      </div>
                    </th>
                    <th>
                      <div class="form-group required">
                        <label> Company/Description</label>
                      </div>
                    </th>
                    <th>Host Name</th>  
                    <th>Mac</th>  
                    <th>Owner</th>  
                    <th>Installed By</th>  
                  
                  
                </tr>
            </thead>
            <tbody>
                <tr >
                  
                    <td>
                        <input class="form-control" style="font-size: small;" placeholder="Select Serial No" v-model="item.serial_no" @input="loadDes()" list="serial" required disabled>
                        <input v-model="item.idStockissuedetails" hidden>
                        <datalist id="serial" >
                           
                            <option v-for="serial in serials" :key="serial" >
                                {{ serial.i_serial_no }}
                            </option>
                            
                      
                        </datalist>
                       
                    </td>
    
    
                    <td>
                        
                        <select style="font-size: small;" class="form-select" v-model="item.location" required>
                      
                           <option v-for="location in locations" :key="location" :value="location.id_location" >
                               {{ location.location_name }}
                           </option>
                           
                     
                       </select>
                        
                    </td>
                    
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="des.i_pdescription" list="Description" placeholder="Select Description" @input="loadSeri()" required disabled>
                       <datalist id="Description" >
                           
                           <option v-for="serial in serials" :key="serial" >
                               {{ serial.i_pdescription }}
                           </option>
                           
                     
                       </datalist>
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.host_name">
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.mac">
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.Owner">
                    </td>
                    <td>
                       <input class="form-control" style="font-size: small;" v-model="item.Installed_By">
                    </td>
                  
                   
                </tr>
    
    
                
            </tbody>
        </table>
    </div>
    
    <div class="navbar">
    
    <div class="d-flex  ">
    
    </div>
    <div class="d-flex  ">
        <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn  ">Update</button>
    </div>
    </div>
    

  </form>
    </div>
    </div>
    </div>
    
    
    
 
    
    
    
    
    </div>
    
    </template>
    
    <script>
     //import {   CButton, } from '@coreui/vue';
     //import httpClient from '@/axios';
     import axios from 'axios';
     import authService from '@/auth.service';
     import NavView from '../../components/NavView.vue'
      import SideBar from '../../components/SideBar.vue'
    
    
    
      export default {
    
        data(){
        return{
        
          access_token:authService.isLoggedIn(),
          userid:authService.isUserid(),
          locations:[],
         
          serials:[],
         
    
          count: 0,
      
          item:{},
    
          mynew:'',
          des:[],
          
          'idgindetail':this.$route.query.id,
         
        };
        },
        mounted(){
      this.loadLocation();
      this.loadSerial();
      this.loadGindet(this.idgindetail);
        },
        
        components:{ 
          NavView,SideBar
        },
    
        computed: {
            
          },
    
        methods:{ 
          

          async loadSeri(){
                
                console.log(this.des.i_pdescription);
               
                try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/iinventory/loadseri',{des:this.des.i_pdescription},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
            this.item.serial_no = data.seri.i_serial_no;
            this.item.location = data.seri.Inventory_location;
          
          
        } catch (error) {
          console.log(error.response);
        }
             
      },
    
          async loadDes(){
                console.log("amila");
              
                try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/iinventory/loaddes',{serial:this.item.serial_no},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.des = data.des;
           this.item.location = data.des.Inventory_location;
          
          
        } catch (error) {
          console.log(error.response);
        }
             
      },
           
            async UpdateGin() {
       
        console.log(this.item);
    
        try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/istockissue/edit1',{userid:this.userid,item:this.item},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.status = data.status;
           if(this.status == 200){
            this.$router.push('/gin/view?id='+data.Gin_id);
           }
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    


      async loadGindet(id) {
        console.log(this.access_token);
        try {
const { data} = await  axios.get('/sfu.evenware.com/web/api/istockissue/edit?id='+id,{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.item = data.item;
           this.des.i_pdescription = data.item.description;
           this.item.location = data.item.Inventory_location;
          
           
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
    
    
            async loadSerial() {
        console.log(this.access_token);
        try {
          const { data} = await  axios.get('/sfu.evenware.com/web/api/iinventory/sugestserial',{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.serials = data.serial;
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
    
    
            async loadLocation() {
        console.log(this.access_token);
        try {
          const { data} = await  axios.get('/sfu.evenware.com/web/api/mlocation/sugestlocation',{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.locations = data.location;
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
        }
      }
    
     </script> 
     <style scoped>
    .purchitem{
        background-color: #0270ce6b;
        padding: 2px 2px 2px 8px;
        border-radius: 3px;
    }
    
    /* apply styles for screens smaller than 768px */
    
    
    
    
    
    </style>