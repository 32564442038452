<template>

    <NavView></NavView>
        <SideBar></SideBar>
    
    <div class="container-fluid mb-5" >
    
      <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span><router-link class="decoration" to="/mitem/index"> Manage Item </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Create Item  </h6>
    
    <div class="container-fluid">
    <div class="card ">
    <div class="card-body">
    
    
    <form @submit.prevent="SaveItem()">
    
   
     <div class="row">
        <div class="col-md-3">
          <div class="form-group required">
            <label style="font-size: small;">Item </label>
            <input v-model="item.item_name" style="font-size: small;" class="form-control" required>
          </div>
        </div>

     </div>
        
        
 
    
    <div class="navbar">
    
    <div class="d-flex  ">
    
    </div>
    <div class="d-flex  ">
        <button type="submit" style="background-color: rgb(71, 144, 255);color: white;" class="btn  btn-sm">Create</button>
    </div>
    </div>



</form>
    
    </div>
    </div>
    </div>
    
    
    
    
    
    
    
    
    </div>
    
    </template>
    
    <script>
     //import {   CButton, } from '@coreui/vue';
     //import httpClient from '@/axios';
     import axios from 'axios';
     import authService from '@/auth.service';
     import NavView from '../../components/NavView.vue'
      import SideBar from '../../components/SideBar.vue'
    
    
    
      export default {
    
        data(){
        return{
        
          access_token:authService.isLoggedIn(),
          userid:authService.isUserid(),
        
         
    
         
          item:{},
       
          
          
      
         
        };
        },
      
        
        components:{ 
          NavView,SideBar
        },
    
        computed: {
            
          },
    
        methods:{ 
          
    
            
           
    
            async SaveItem() {
        console.log(this.access_token);
        console.log(this.item);
    
        try {
          const { data} = await  axios.post('/sfu.evenware.com/web/api/mitems/create',{userid:this.userid,item:this.item},{
            headers:{
              'Authorization':'Bearer '+this.access_token,
              'Content-type': 'application/json',
             
            }
           });
           console.log(data);
           this.status = data.status;
           if(this.status == 200){
            this.$router.push('/mitem/index');
           }
          
        } catch (error) {
          console.log(error.response);
        }
      },
    
    
    
    
        
    
    
    
        }
      }
    
     </script> 
     <style scoped>
    .purchitem{
        background-color: #0270ce6b;
        padding: 2px 2px 2px 8px;
        border-radius: 3px;
    }
    
    /* apply styles for screens smaller than 768px */
    
    
    
    
    
    </style>