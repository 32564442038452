<template>
  <NavView></NavView>
    <SideBar></SideBar>
<div class="container-fluid mb-5" >
    <!-- <h4 class="manage-boder">Manage Customer</h4>  -->
    <h6 class="manage-boder pt-2 font1 font-bold1"> <router-link class="decoration" to="/"> Home </router-link><span class="pfimg3"><i class="fa fa-angle-right" aria-hidden="true"></i></span> Manage Location  </h6>
    <div class="navbar">

      <div class="d-flex">
        <input style="font-size: small;" v-model="search" class="form-control me-2 width7" type="search" placeholder="Search" aria-label="Search">
      </div>
  

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
     

      <button  style="background-color: rgb(71, 144, 255);color: white;" class="btn btn-sm  mb-1"> <router-link class="decoration" style="color: white;" to="/location/create"> Create Location</router-link></button>
     

    </div>
  </div>


<div class="table-responsive">
<table style="font-size: small;" id=""  class="mt-2  table  table-striped table-hover table-bordered">
  <thead>
    <tr>
      <th>#</th>
      <th>Location</th>
      <th>Status</th>
     
      <th>Action</th>
  
      
      
     
    </tr>
  </thead>
 
    
 
  <tbody>
   
    <!-- v-for="key in count" :key="key" -->
    <tr  v-for="(location, index) in filteredLocations" :key="location.id" >
      <th >{{index+1}}</th>
      <th class="d-none"> {{location.id}} </th>
      <td>{{location.location_name}}</td>
      <td v-show="location.status == 1" >Active</td>
      <td v-show="location.status == 2">Inactive</td>
      <td>
          <button  style="background-color: rgb(71, 144, 255);color: white; font-size: small; " class="btn btn-sm  mb-1" @click="LocUpdate(location.id_location)"> Update</button>
        </td>
  
      
     <!--  <td ><span class="badge badge-danger border-radius0">Innactive</span></td> -->
     
   
    </tr>
    
    
  </tbody>
</table>
</div>




</div>


</template>
  
  <script>
   //import {   CButton, } from '@coreui/vue';
   //import httpClient from '@/axios';
   import axios from 'axios';
   import authService from '@/auth.service';
   import NavView from '../../components/NavView.vue'
    import SideBar from '../../components/SideBar.vue'
  
  
  
  export default {
      components:{ 
        NavView,SideBar
      },
  
      mounted(){
    this.loadMiinventory()
      },
     
      computed: {
        filteredLocations() {
        return  this.locations.filter((location) => {
          return (
            location.location_name.toLowerCase().includes(this.search.toLowerCase()) ||
            location.username.toLowerCase().includes(this.search.toLowerCase()) 
                  
            
          );
        });
       
      },
  
  
  
      },
  
      data(){
      return{
        
    locations:[],
      search: "",
        access_token:authService.isLoggedIn(),
       
       
          
  
        
    
       
      };
      },
  
      
  
  
     methods:{ 
  
  
      LocUpdate(id){
        this.$router.push('/location/update?id='+id);
      },
   
  
      async loadMiinventory() {
      console.log(this.access_token);
      try {
        const { data} = await  axios.get('/sfu.evenware.com/web/api/mlocation/index',{
          headers:{
            'Authorization':'Bearer '+this.access_token,
            'Content-type': 'application/json',
           
          }
         });
         console.log(data);
         this.locations = data.location;
        
      } catch (error) {
        console.log(error.response);
      }
    },
     
  
   
  
  
  
  
  
  }
  
  
  
  
  };
  
  </script>
  
  <style scoped>
  .button1{
    width: 10px !important;
  }
  .border1{
    border: none;
    background-color: #0d6efd;
  }
  .font-color1:hover{
    color: black !important;
    cursor: pointer;
  }
  .manage-boder{
    border-bottom: 1px solid #E9E9E3;
    border-top: 1px solid #E9E9E3;
  }
  .border-radius0{
        border-radius:0px !important ;
  }
  .font1{
        font-size: 13px !important;
     
      }
      .font-bold1{
        font-weight: bold;
      }
      .manage-boder{
      border-bottom: 2px solid #E9E9E3;
      border-top: 2px solid #E9E9E3;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      
      }
      .font1{
        font-size: 13px !important;
     
      }
      .font-bold1{
        font-weight: bold !important;
      }
      .colr1{
          color:#A5A5A3;
          
      }
      .decoration{
      text-decoration: none;
      color:#A5A5A3  ;
    }
    .width7{
      width: 250px;
    }
  
  </style>