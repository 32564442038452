<template>
<div>
  <h1 >jquery </h1><br><br>
    <button class="btn-success myatc" value="10"  id="">amila</button>
    <button class="btn-success myatc" value="20" id="">amila</button><br><br>
    <p> amila thilanka </p>

    <h4 id="changeclr">click and color change</h4>


    <label for="fav_brand" >Favourite Brand</label>
    <input type="text" name="fav_brand" list="brands" id="fav_brand" placeholder="type" >
    <datalist id="brands"  >
        <option  >dell</option>
        <option  >hp</option>
        <option  >leneovo</option>
        <option  >hp super</option>

    </datalist>

<br><br>

    <select   class="form-control " @change="onChange(igrn.name)"  id="" v-model="igrn.name"   >

    <option value="1" >amila</option>
    <option value="2">thilanka</option>
    <option value="3">chandrasiri</option>

    </select>


</div>
</template>

<script>
 
import $ from "jquery";





 
    export default {

      mounted() {
        this.loadJquary()
      },




      data (){

        return {
          igrn:{

          },
        }
      },

      


      methods: {
        onChange(value){
console.log('amila');
console.log(value);
        },







loadJquary() {
 
$(document).ready(function () {
  

          $('.myatc').click(function(){
            $(this).html('Clicked');
            // $(this).html($(this).val());
            $(this).removeClass('btn-success');
            $(this).addClass('btn-primary');
            // $(this).hide();
          });


            $("p").click(function(){
              $(this).hide();
            });

            $('#changeclr').click(function(){
              $('#changeclr').addClass('bgl-my-clr');
            });


});

},




      },

    }

</script>



<style scoped>
.bgl-my-clr {
  color: red;
}



</style>