import axios from 'axios';






const authService = {
    
    user: null,
   async Login(formData){
    try {
        const {status, data} = await axios.post('/sfu.evenware.com/web/api/user/login',formData);
        console.log(status,data);
        //this.token = data.access_token;
       if(status === 200){
        this.setUser(data);
        return {success: true}; 
       }
      } catch (error) {
        console.log(error.response);
        this.errorsf = error.response.data.errors.username
        this.errors2f = error.response.data.errors.password
        if( this.errorsf){
            this.errors = error.response.data.errors.username[0];
        }
        if( this.errors2f){
            this.errors2 = error.response.data.errors.password[0];
        }
        
        return {
            success: false,
            errors: this.errors,
            errors2: this.errors2,
            
            
        }
      }
    },

    
    setUser(user){
        this.user = user;
        localStorage.setItem('ACCESS_TOKEN', user.access_token);
        localStorage.setItem('USERNAME', user.username);
        localStorage.setItem('USERID', user.id);
        localStorage.setItem('SUPERADMIN', user.superadmin);
    },
    isLoggedIn(){
        return localStorage.getItem('ACCESS_TOKEN');
       
    },

    isUsername(){
        return localStorage.getItem('USERNAME');
    },
    isUserid(){
        return localStorage.getItem('USERID');
    },
    isSuperadmin(){
        return localStorage.getItem('SUPERADMIN');
    },

    
    
    
    Logout(){
        
       
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('USERNAME');
        localStorage.removeItem('USERID');
        localStorage.removeItem('SUPERADMIN');
        this.$router.push({name: 'LoginView'})
        
        
    }
};



export default authService;